.custom-loader-container {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
}

.custom-loader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80px;
}

.custom-loader-dot {
  width: 16px;
  height: 16px;
  background-color: #ff9900;
  border-radius: 50%;
  animation: bounce 1.2s infinite ease-in-out;
}

.custom-loader-dot:nth-child(2) {
  animation-delay: -0.4s;
}

.custom-loader-dot:nth-child(3) {
  animation-delay: -0.8s;
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
